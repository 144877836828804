import React from 'react';
import * as styles from './embed-video.module.scss';

const EmbedVideo = ({ videoSrcURL, ...props }) => (
  <div className={styles.videoWrapper}>
    <div className={styles.videoInner}>
      <iframe
        src={videoSrcURL + '?autoplay=1'}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded video"
      />
    </div>
  </div>
);
export default EmbedVideo;