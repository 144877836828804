// extracted by mini-css-extract-plugin
export var singleActivity__mediaImageWrapper = "single-activity-module--single-activity__media-image-wrapper--1iY0J";
export var singleActivity__mediaCarousel = "single-activity-module--single-activity__media-carousel--1cPVn";
export var singleActivity__contentWrapper = "single-activity-module--single-activity__content-wrapper--2f9xC";
export var singleActivity__mediaImage = "single-activity-module--single-activity__media-image--3oHXL";
export var singleActivity__headingWrapper = "single-activity-module--single-activity__heading-wrapper--1PCGa";
export var singleActivity__metaWrapper = "single-activity-module--single-activity__meta-wrapper--3w6k-";
export var singleActivity__metaFavorite = "single-activity-module--single-activity__meta-favorite--3Rlci";
export var singleActivity__planYourTrip = "single-activity-module--single-activity__plan-your-trip--3xo_J";
export var singleActivity__metaLocations = "single-activity-module--single-activity__meta-locations--z81BO";
export var singleActivity__description = "single-activity-module--single-activity__description--37HWS";
export var singleActivity__offers = "single-activity-module--single-activity__offers--x31Xi";
export var offer__container = "single-activity-module--offer__container--1pagO";
export var offer__heading = "single-activity-module--offer__heading--gcXUk";
export var offer__description = "single-activity-module--offer__description--3nk4l";
export var locationColorGreen = "single-activity-module--location-color-green--2s_AS";
export var locationColorRed = "single-activity-module--location-color-red--2JZyG";
export var locationColorBlue = "single-activity-module--location-color-blue--g2smk";
export var locationColorPurple = "single-activity-module--location-color-purple--17YdQ";
export var singleActivity__wrapper = "single-activity-module--single-activity__wrapper--3_BV_";
export var singleActivity__wrapperInner = "single-activity-module--single-activity__wrapper-inner--1BFNQ";