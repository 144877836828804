import React, { useEffect, useState } from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import { ActivityModel, OfferModel } from '@/src/models/activity.model';
import { TranslationService } from '@/src/services/translation.service';
import { TranslationProvider, useTranslation } from '@/src/contexts/translation.context';
import Header from '@/src/components/header/header';
import Navbar from '@/src/components/navbar/navbar';
import BottomSheet from '@/src/components/bottom-sheet/bottom-sheet';
import SignupFunnel from '@/src/components/signup-funnel/signup-funnel';
import { getActivityService } from '@/src/services/activity.service';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import Video from '@/src/components/video/video';
import EmbedVideo from '@/src/components/embed-video/embed-video';
import * as styles from './single-activity.module.scss';
import Location from '@/src/components/location/location';
import StarIcon from '@/assets/icons/star.inline.svg';
import StarFilledIcon from '@/assets/icons/star-filled.inline.svg';
import { Badge } from '@/src/components/badge/badge';
import { BiMap } from 'react-icons/bi';
import Slider from "react-slick";
import sanitizeHtml from 'sanitize-html';
import Footer from '@/src/components/footer/footer';
import { CustomImage } from '@/src/components/image/image';
import { EventService } from '@/src/services/events.service';

type OfferProps = {
  offer: OfferModel;
};

function Offer({ offer }: OfferProps) {
  const translation = useTranslation();
  const title = offer.title[translation.lang];
  let description: string;

  if(offer.amount.length > 0) {
    description = offer.amount;
  } else {
    let percentageOfferSuffix = translation.translate('activities.percentage_offer_suffix');
    description = '-' + offer.percentage + '% ' + percentageOfferSuffix;
  }

  if(!offer.amount && !offer.percentage) {
    description = '';
  }

  return (
    <div className={styles.offer__container}>
      {!!description ? <p className={styles.offer__heading}>{description}</p> : null}
      <p className={styles.offer__description}>{title}</p>
    </div>
  );
}

export default function SingleActivityPage({ pageContext }: PageProps) {
  const activity: any = (pageContext as any).activity;
  const [translation] = useState(new TranslationService(pageContext));
  const [isFavourite, setIsFavourite] = useState(false);
  const [funnelTest, setFunnelTest] = useState(false);
  const {isAuthenticated} = useAuthStatus();

  useEffect(() => {
    const activityService = getActivityService();
    activityService.init();
    const subscription = activityService.favouriteActivities$.subscribe(activities => setIsFavourite(activities.includes(activity.id)));
    
    // Track event
    const eventService = new EventService();
    eventService.logEvent(
      'single_activity',
      {
        activityId: activity.id
      }
    );

    return () => {
      subscription.unsubscribe();
    }
  }, []);

  const markAsFavourite = () => {
    if(isAuthenticated) {
      const activityService = getActivityService();
      activityService.setFavourite(activity.id, !isFavourite);
    } else {
      setFunnelTest(true);
    }
  };

  const slickSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 20
  };

  const locationClassNames = {
    north: styles.locationColorGreen,
    west: styles.locationColorRed,
    east: styles.locationColorPurple,
    south: styles.locationColorBlue
  };

  const activityTypeTranslations = {
    free: translation.translate('single_activity.activity_type.free'),
    paid: translation.translate('single_activity.activity_type.paid')
  };

  let mediaType = '';

  if(activity.videoType === 'embed' && activity.embedUrl !== '') {
    mediaType = 'embed';
  } else if(activity.videoType === 'upload' && activity.videos.length > 0) {
    mediaType = 'video';
  } else if(activity.images.length === 1) {
    mediaType = 'image';
  } else if(activity.images.length > 1) {
    mediaType = 'images';
  }

  return (
    <>
      <TranslationProvider value={translation}>
        <Header></Header>

        <div className="scrollable">
          <div className={styles.singleActivity__wrapper + ' app__wrapper app__wrapper--single-activity'}>
            <div className={styles.singleActivity__wrapperInner}>
              {mediaType !== '' &&
                <div className={styles.singleActivity__media}>
                  {mediaType === 'embed' &&
                    <div className={styles.singleActivity__mediaVideo}>
                      <EmbedVideo
                        videoSrcURL={activity.embedUrl}
                      />
                    </div>}
                  {mediaType === 'video' &&
                    <div className={styles.singleActivity__mediaVideo}>
                      <Video
                        videoSrcURL={activity.videos[0].url}
                        videoTitle={activity.videos[0].name}
                      />
                    </div>}
                  {mediaType === 'image' &&
                    <div className={styles.singleActivity__mediaImageWrapper}>
                      <div className={styles.singleActivity__mediaImage}>
                        <CustomImage
                          src={`uploads/${activity.id}/image/${activity.images[0].id}`}
                          alt={activity.images[0].name}
                          width={1000}
                          height={1000}></CustomImage>
                      </div>
                    </div>}
                  {mediaType === 'images' &&
                    <div className={styles.singleActivity__mediaCarousel}>
                      <Slider {...slickSettings}>
                        {activity.images.map((image, index) => {
                          return (
                            <CustomImage
                              key={index}
                              src={`uploads/${activity.id}/image/${image.id}`}
                              alt={image.name}
                              width={1000}
                              height={1000}></CustomImage>
                          );
                        })}
                      </Slider>
                    </div>}
                </div>}

              <div className={styles.singleActivity__contentWrapper}>
                <div className={styles.singleActivity__headingWrapper}>
                  <h1>{activity.title[translation.lang]}</h1>
                  {activity?.category?.name &&
                    <p>{activity.category.name[translation.lang]}</p>
                  }
                </div>

                <div className={styles.singleActivity__metaWrapper}>
                  <div className={styles.singleActivity__metaLocations + ' ' + locationClassNames[activity.location[0]]}>
                    {activity.location.map((location, index) => (
                      <Location
                        key={index}
                        location={location} />
                    ))}
                    <Link to={`/${translation.lang}/activities`} state={{ fromSingleActivity: true, activityId: activity.id }}>
                      <Badge.Filled text={translation.translate('activities.show_on_map')} icon={<BiMap />}></Badge.Filled>
                    </Link>
                  </div>
                  <div className={styles.singleActivity__metaFavorite}>
                    <button onClick={() => markAsFavourite()}>
                      {isFavourite ? <StarFilledIcon></StarFilledIcon> : <StarIcon></StarIcon>}
                    </button>
                  </div>
                </div>

                {activity.offers.length > 0 &&
                  <div className={styles.singleActivity__offers}>
                    <h2>{translation.translate('single_activity.offers')}</h2>
                    {activity.offers.map((offer, index) => (
                      <Offer key={index} offer={offer}></Offer>
                    ))}
                  </div>
                }

                <div
                  className={styles.singleActivity__description}
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(activity.description[translation.lang], {allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ])}) }}>
                </div>

                <div className={styles.singleActivity__planYourTrip}>
                  <Link to={`/${translation.lang}/book-ticket`}><Badge.Filled text={translation.translate('activities.plan_your_trip')}></Badge.Filled></Link>
                </div>

              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>

        <Navbar></Navbar>
        <BottomSheet open={funnelTest}>
          <SignupFunnel
            onClose={() => setFunnelTest(false)}
            actionKey="favourite_activity"></SignupFunnel>
        </BottomSheet>
      </TranslationProvider>
    </>
  );
}