import React from 'react';
import * as styles from './video.module.scss';

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className={styles.videoWrapper}>
    <div className={styles.videoInner}>
      <video autoPlay loop muted playsInline controls>
        <source src={videoSrcURL} type="video/mp4"></source>
      </video>
    </div>
  </div>
);
export default Video;